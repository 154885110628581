import React, {  useContext } from 'react';
import { AuthContext } from '../context/AuthContext';
import {
    Route,
    Redirect
  } from 'react-router-dom';

  


const AuthenticatedRoute = ({ children,grant,send_to,...rest}) => {
    const authContext = useContext(AuthContext);
    const checkAuth = grant => {
        return authContext.checkAccesslist(grant)
    }
    return (
      <Route {...rest} render={() => 
        checkAuth(grant) ? (
            <>
           {children}
           </>
        ) : (
          <Redirect to={send_to} />
        )
        
      } />
    )
  }

  export default AuthenticatedRoute;