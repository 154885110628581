import React, { useContext, useState ,useEffect, useRef} from 'react';
import {AuthContext} from './../context/AuthContext';
import Hyperlink from './common/Hyperlink';

import logoLoggedIn from './../images/mv-ko-bg.svg';
import logoLoggedOut from './../images/mv-ko-bg.svg';
import menuEarth from './../images/menu-earth.jpg';
import profileImg from './../images/profile.jpg';
import faqImg from './../images/faq.jpg';
import logoutImg from './../images/exit.jpg';

const Header = ({pageSlug}) => {
  const ref = useRef();
  const authContext = useContext(AuthContext);
  const [showProfileMenu,SetshowProfileMenu] = useState(false)
  const checkAuth = grant => {
      return authContext.checkAccesslist(grant)
  }
  const getClass = () => {
    if (checkAuth(['loggedIn'])) {
        return 'header dark-text loggedIn ' + pageSlug;
    } else {
      return 'header dark-text loggedOut ' + pageSlug;
    }
  }

  

  const doLogout = e => {
    e.preventDefault();
    authContext.logout();
  }
  
  const toggleProfile = () => {
      let tempToggle = !showProfileMenu;
      SetshowProfileMenu(tempToggle);
  }

  useEffect(() => {
    const checkIfClickedOutside = e => {
      // If the menu is open and the clicked target is not within the menu,
      // then close the menu
      if (showProfileMenu && ref.current && !ref.current.contains(e.target)) {
        SetshowProfileMenu(false)
      }
    }

    document.addEventListener("mousedown", checkIfClickedOutside)

    return () => {
      // Cleanup the event listener
      document.removeEventListener("mousedown", checkIfClickedOutside)
    }
  }, [showProfileMenu])
  
  return (
    <header className={getClass()}>
        <div className="container">
          {checkAuth(['loggedIn']) ? (
            <>
              <div className="header-logo ">
                  <Hyperlink to="/dashboard" >
                            <img className="w-32 h-full" src={logoLoggedIn} alt="Logo" />
                            {/* <span>MediaVax</span> */}
                        </Hyperlink>
                    </div>
                    <div className="header-menu black-text"   ref={ref}  onClick={toggleProfile}><span className="header_firstname">{authContext.getUserInfo('firstName')}</span> 
                    {showProfileMenu ? (<i className="fas fa-chevron-up"></i>) : (<i className="fas fa-chevron-down"></i>)}
                    {showProfileMenu ? (
                      <div className="profileMenu" >
                          {/* <Hyperlink to='/loading' onClick={toggleProfile}>
                              <span>
                                <img className="w-32 h-full" src={faqImg} alt="Faq" />
                                </span> Loading
                          </Hyperlink> */}
                          <Hyperlink to='/profile' onClick={toggleProfile}>
                              <span>
                                <img className="w-32 h-full" src={profileImg} alt="Faq" />
                                </span> Profile
                          </Hyperlink>
                          <Hyperlink to='/faq' onClick={toggleProfile}>
                              <span>
                                <img className="w-32 h-full" src={faqImg} alt="Faq" />
                                </span> FAQs
                          </Hyperlink>
                          {checkAuth(['loggedIn','admin']) ? (
                          <Hyperlink to='/faq-admin' onClick={toggleProfile}>
                              <span>
                                <img className="w-32 h-full" src={faqImg} alt="FaqAdmin" />
                                </span> Edit FAQs
                          </Hyperlink>) : null}
                          <a href='https://mediavax.ai/' target="_blank" rel="noopener noreferrer" onClick={toggleProfile} >
                              <span>
                                  <img className="w-32 h-full" src={menuEarth} alt="mediavax.ai" />
                              </span> mediavax.ai
                          </a>
                          <a href="/profile" onClick={doLogout} >
                              <span>
                                  <img className="w-32 h-full" src={logoutImg} alt="Logout" />
                              </span> Logout
                          </a>
                      </div>
                      ) : (<></>)}
                    
                    </div>
                    
            </>
          ) : (
            <div className="header-logo  ">
                <Hyperlink to="/" >
                    <img className="w-32 h-full" src={logoLoggedOut} alt="Logo" />
                </Hyperlink>
            </div>
          )
          } 
      </div>
    </header>
  );
};

export default Header;