import React, {  Component } from 'react'
import Header from './components/Header';
import Footer from './components/Footer';
import './App.css';
export class AppShell extends Component {
    constructor(props) {
        super(props);
        this.getClass = this.getClass.bind(this);
    }
    getClass = () => {
        const { pageSlug} = this.props;
        return 'page-wrapper ' + pageSlug;
    }
    render() {
        const { children,pageSlug,showPopUps,changePopups} = this.props;
        return (
            <div className={this.getClass()}>
                <Header pageSlug={pageSlug}/>
                <div className="page-content">
                    {children}
                </div>
                <Footer pageSlug={pageSlug} showPopUps={showPopUps} changePopups={changePopups} /> 
            </div>
        )
    }
}

export default AppShell
