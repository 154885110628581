import React from 'react'
import Hyperlink from './common/Hyperlink';
import logoLoggedOut from './../images/mv-ko-bg.svg';
import './../App.css';



class ErrorBoundary extends React.Component {
    constructor(props) {
      super(props);
      this.state = { hasError: false, errorMsgs: '' };

      this.getYear = this.getYear.bind(this);
    }
  
    static getDerivedStateFromError(error) {
      // Update state so the next render will show the fallback UI.

      return { hasError: true };
    }
  
    componentDidCatch(error, errorInfo) {
        localStorage.removeItem('emerging_terms');
        localStorage.removeItem('trending_terms');
        localStorage.removeItem('recorded_last_date');
        localStorage.removeItem('lastDates');
        localStorage.removeItem('lastDate');
        localStorage.removeItem('last_row_update');
        localStorage.removeItem('expiresAt');
        localStorage.removeItem('userInfo');

        let last_tweets =  localStorage.getItem('last_tweets'); 
        if (last_tweets !== null) {
            last_tweets = JSON.parse(last_tweets);
            for (let l in last_tweets) {
                localStorage.removeItem(last_tweets[l]);
            }
        }
        localStorage.removeItem('last_tweets');
        localStorage.removeItem('last_tweets_time');


        this.setState({errorMsgs: error.message});
      // You can also log the error to an error reporting service
    //   logErrorToMyService(error, errorInfo);
    }

    getYear = () => {
      let now  = new Date(); 
      return new Intl.DateTimeFormat('en', { year: 'numeric' }).format(now);
    }
  
    render() {

      if (this.state.hasError) {
        // You can render any custom fallback UI
        return (
            
            <div className="pageStartWrapper error">
                <div className="pageLeft">
                   <header className="header dark-text loggedOut error">
                        <div className="container">
                          <div className="header-logo  ">
                              <Hyperlink to="/" >
                                  <img className="w-32 h-full" src={logoLoggedOut} alt="Logo" />
                              </Hyperlink>
                          </div>
                      </div>
                    </header>
                    <div class="pageStartcontent">
                        <section class="login">
                            <div class="container">
                                <div class="login">
                                    <div class="preLogin">
                                        <h1>Something Went Wrong</h1>
                                    </div>
                                    <div class="errorContent"><p>We're still optimizing this product.</p>
                                         <p>Please refresh your browser or try again later.</p>
                                    </div>
                                       
                                </div>
                              </div>
                          </section>
                      </div>
                </div>
                <div className="pageRight">
                    <div className="info_block">
                        <div className="backimage"></div>
                        <Hyperlink
                            className="signup"
                            to="sign-up"
                            >Sign up for Mediavax</Hyperlink>
                        <h2>Explore disinformation narratives on social media with a MediaVax account</h2>
                        <div className="content"><p>This is an initial <strong>alpha release</strong> product, developed to allow you to dive into disinformation 
                            narratives and provide feedback. As such, some features are not yet fully functional.</p>
                            <p>To learn more, visit <a href="https://mediavax.ai/" target="_blank" rel="noopener noreferrer" >mediavax.ai</a>. 
                            If you have questions, please email support at <a href="mailto:Diane@mediavax.ai" target="_blank" rel="noopener noreferrer" >Diane -MediaVax User Support</a></p>
                       </div>
                    </div>
                    <div className="copyright">© {this.getYear()} MediaVax, ALL RIGHTS RESERVED</div>
                </div>
                
            </div>
            
            
            );
      }
  
      return this.props.children; 
    }
}

export default ErrorBoundary
