import React from 'react';
import defaultLoadingGif from './../images/ATB3o.gif';
//https://lottiefiles.com/73675-bird-flying
const Loading = () => {
    // const { height} = useWindowDimensions();
    // const top = (height/2)-50;
    // const defaultOptions = {
    //   loop: true,
    //   autoplay: true, 
    //   animationData: animationData,
    //   rendererSettings: {
    //     preserveAspectRatio: 'xMidYMid slice'
    //   }
    // };
    return (
      <div className="loadingPage">
      <section className="loadingWrap">
          {/* <Lottie className="lottieBird" options={defaultOptions}
                  height={400}
                  width={400}/> */}
          <img src={defaultLoadingGif} alt="Loading Page"   />
      </section>
      </div>
      
    )
  }

// export { Loading };
export default Loading;
