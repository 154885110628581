import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
const Hyperlink = ({ children, to, target,className,icon,size,text}) => (
  <>
    {children ? (
      <Link
          to={to}
          className={className}
          target={target}
        >
          {children}
      </Link>
    ) : (
      <Link
          to={to}
          className={className}
          target={target}
        >
          <FontAwesomeIcon icon={icon} size={size} /> {text}
      </Link>
    )}
  </>
  
);

export default Hyperlink;
