import React, { useContext,useState } from 'react';
import {AuthContext} from './../context/AuthContext';
// import feedbackImg from './../images/feedback-icon2.png';
import Hyperlink from './common/Hyperlink';

const Footer = ({showPopUps,changePopups}) => {
  const authContext = useContext(AuthContext);
  const [clearCache,SetClearCache] = useState(false);
  const checkAuth = (grant) => {
      return authContext.checkAccesslist(grant)
  }

  const getToggleClass = () => {
      if (showPopUps) {
          return 'enable fas fa-database ';
      } else {
          return 'disable fas fa-database';
      }
  }

  const changeToggle = () => {
    let tempToggle = !showPopUps;
    changePopups(tempToggle);
}

const getCacheClass = () => {
  if (clearCache) {
      return 'enable fas fa-eraser ';
  } else {
      return 'disable fas fa-eraser';
  }
}

const changeCache = () => {
  SetClearCache(true);
  localStorage.removeItem('emerging_terms');
  localStorage.removeItem('trending_terms');
  localStorage.removeItem('recorded_last_date');
  localStorage.removeItem('lastDates');
  localStorage.removeItem('lastDate');
  localStorage.removeItem('last_row_update');
  let last_tweets =  localStorage.getItem('last_tweets'); 
  if (last_tweets !== null) {
      last_tweets = JSON.parse(last_tweets);
      for (let l in last_tweets) {
          localStorage.removeItem(last_tweets[l]);
      }
  }
  localStorage.removeItem('last_tweets');
  localStorage.removeItem('last_tweets_time');
  
  setTimeout( () => {
    SetClearCache(false);
  },1000); 
}


  return (
    <footer  className="footer">
        <div className="container">
        {checkAuth(['loggedIn']) ? (
          <>
            {/* <a className="footer-feedback dark-text" href="https://www.surveymonkey.com/r/F95B5GY" target="_blank" rel="noopener noreferrer" >
                <img src={feedbackImg} alt="Feedback" /><span>Please provide us feedback by rating this app</span>
            </a> */}
          </> ) : (<></>)}
            <div className="footer-bottom dark-text">
                <div className="footer-left">Copyright © 2022 MediaVax</div>
                <div className="footer-right">
                  <a href="https://mediavax.ai/privacy-policy/" target="_blank" rel="noopener noreferrer" >Privacy Policy</a> &nbsp;|&nbsp; 
                  <a href="https://mediavax.ai/terms-of-use/" target="_blank" rel="noopener noreferrer" >Terms of Use</a> &nbsp;|&nbsp; 
                  <a href="https://mediavax.ai/privacy-policy/" target="_blank" rel="noopener noreferrer" >Cookie Policy</a>
                </div>
            </div>
        </div>
        {checkAuth(['loggedIn','admin']) ? (
          <>
            <div className="adminLink">
              <Hyperlink to="/admin/users" >
                  <i title="Admin" className="fas fa-cogs"></i>
                {/* <FontAwesomeIcon icon={faCogs} size="2x" /> */}
              </Hyperlink>
              <i title="Show/Hide Graph Data" className={getToggleClass()}  onClick={changeToggle}></i>
              <i title="Clear Cache" className={getCacheClass()}  onClick={changeCache}></i>

            </div>
          </>
        ):(<></>)}
    </footer>
  );
};

export default Footer;