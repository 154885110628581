import React from 'react';


class Password extends React.Component {
  constructor(props) {
    super(props);
    this.state = {isToggleOn: true};

    // This binding is necessary to make `this` work in the callback
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick() {
    this.setState(prevState => ({
      isToggleOn: !prevState.isToggleOn
    }));
  }


  render() {
    const { ariaLabel,name,placeholder,onChange,onFocus,onBlur,defaultValue,autocomplete} = this.props;
    const {isToggleOn } = this.state
    // 
    return (  
      <>
      <input
        aria-label={ariaLabel}
        name={name}
        placeholder={placeholder}
        type={isToggleOn ? 'password' : 'text'}
        value={defaultValue}
        autoComplete={autocomplete}
        onChange={onChange}
        onFocus={onFocus}
        onBlur={onBlur}
      />
      <i className={isToggleOn ? 'fas fa-eye-slash' : 'fas fa-eye'} onClick={this.handleClick}></i>
      </>
    );
  }
}

export default Password;
