import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';


class Button extends React.Component {
  
  render() {
    // 
    return (  
      <>
        <button
          className={this.props.className}
          type={this.props.type}
          onClick={this.props.onClick}
          style={this.props.style}
        >
          {this.props.loading ? (
            <span className="flex items-center">
              <FontAwesomeIcon icon={faCircleNotch} spin />
              <span className="ml-2">Loading...</span>
            </span>
          ) : (
            <span>{this.props.text}</span>
          )}
        </button>
      </>
    );
  }
}

export default Button;

