import React, { useState, useContext } from 'react';
import {
  Redirect,
} from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';
import { publicFetch } from '../utils/fetch';
import Button from './common/Button';
import FormInput from './forms/FormInput';
import FormPassword from './forms/FormPassword';
import Hyperlink from './common/Hyperlink';
import validator from 'validator';
import RememberMe from './forms/RememberMe';


const Login = ({children,afterContent}) => {
  const authContext = useContext(AuthContext);

  
  const [showSuccess  , setShowSuccess] = useState(false);
  const [showError    , setShowError] = useState(false);
  const [loginSuccess, setLoginSuccess] = useState('');
  const [loginError, setLoginError] = useState('');
  const [loginLoading, setLoginLoading] = useState(false);
  const [redirectOnLogin,setRedirectOnLogin] = useState(false);
  const [emailValid  , setEmailValid] = useState(true);
  const [rememberMeChecked  , setRememberMeChecked] = useState(false);
  const [emailMessage  , ] = useState('Please use a valid email address!');
  const [passwordValid  , setPasswordValid] = useState(true);
  const [passwordMessage  , ] = useState('Password can not be empty!');
  const [userdata,setUserdata] = useState({
    email: '',
    password: ''
  });


  const SubmitCredentials = async credentials => {
    let message = '';
    try {
      setLoginLoading(true);
      const { data } = await publicFetch.post('authenticate', credentials);
      setLoginLoading(false);
      data.rememberMe = rememberMeChecked;
      setLoginSuccess(data.message);
      setLoginError(null);
      setShowSuccess(true);
      setShowError(false);

      
      setTimeout( () => {
          setRedirectOnLogin(true);
          authContext.setAuthState(data);
      },700); 
    } catch (error) {
      setLoginLoading(false);
      const { response } = error;
      if (typeof response == 'undefined') {
        message = 'Server did not respond';
      } else if (typeof response.data == 'undefined') {
          message = 'Server did not respond';
      } else {
        const data = response.data;
        if (!data.message) {
            message = 'Server did not return message';

        } else {
            message = data.message;
        }
    }
      setLoginError(message);
      setLoginSuccess(null);
      setShowSuccess(false);
      setShowError(true);
    }
  };
  const DoContinue = e => {
    e.preventDefault();
    const {email,password} = userdata
    let goon = 1;
    if (validator.isEmail(email)) {
      setEmailValid( true);
    } else {
      setEmailValid( false);
        goon = 0;
    }
    if (password.length > 0) {
      setPasswordValid( true);
    } else {
      setPasswordValid( false);
        goon = 0;
    }
    if (!goon) return;
    SubmitCredentials(userdata);
    
    
  }

  const handleChange = e => {
    let thisData = {
      email : userdata.email,
      password : userdata.password
    }
    if (e.target.name === 'email') {
      thisData['email'] = e.target.value;
    }
    if (e.target.name === 'password') {
      thisData['password'] = e.target.value;
    }
    setEmailValid( true);
    setPasswordValid(true);
    setUserdata(thisData);
  }

  const checkCookieAccept = () => {
    let CookiesIsAccepted = authContext.CookiesIsAccepted();
    return !CookiesIsAccepted;
  }


  const [showCookieAccept,SetShowCookieAccept] = useState(checkCookieAccept());

  const acceptCookies = () => {
      authContext.SetCookiesAccepted();
      SetShowCookieAccept();
  }

  const setRememberMe = (IsChecked) => {
    setRememberMeChecked(IsChecked);
  }

  return (
    <>
      {redirectOnLogin && <Redirect to="/dashboard" /> }
    
    <div  className="container">
      <div className="login">
        <div className="preLogin">
            {children}
        </div>
        <div className="loginContent">
        <form>
            <label className="black-text">Email</label>
            <FormInput
                ariaLabel="Email"
                name="email"
                type="text"
                placeholder="Email"
                onChange={handleChange}
                defaultValue={userdata.email}
                autoComplete="username"
                valid={!emailValid}
                validMessage={emailMessage}
              />
            <label className="black-text">Password</label>
            <FormPassword
                ariaLabel="Password"
                name="password"
                type="password"
                placeholder="Password"
                onChange={handleChange}
                autoComplete="current-password"
                defaultValue={userdata.password}
                showValidation={false}
                valid={passwordValid}
                validMessage={passwordMessage}
              />
              <div className="login-links">
                  <RememberMe handleChange={setRememberMe} />
                  <Hyperlink
                    className="forgot-password dark-blue-text"
                    to="forgot-password"
                    text="Forgot your password?"
                  >Forgot Password?</Hyperlink>
              </div>
            <div className="form-submit">
            <Button
                className="btn "
                type="submit"
                text="Login"
                loading={loginLoading}
                onClick={DoContinue}
              />
            </div>
            {showSuccess ? (<div className="message success">{loginSuccess}</div>) : (<></>)}    
            {showError ? (<div className="message error">{loginError}</div>) : (<></>)} 
              
            <div className="bottom-links" >
                <span className="new black-text">New?</span>
                <Hyperlink
                    className="signup dark-blue-text"
                    to="sign-up"
                  >Get started here</Hyperlink>
                
            </div>

            <div className="terms light-blue-text">By signing in, you agree to MediaVax’s<br />
                <a className="term_link light-blue-text" 
                    href="https://mediavax.ai/privacy-policy/" 
                    target="_blank" 
                    rel="noopener noreferrer" >Privacy Policy</a> and <a className="term_link light-blue-text" 
                                                                          href="https://mediavax.ai/terms-of-use/" 
                                                                          target="_blank" 
                                                                          rel="noopener noreferrer" >Terms of Service</a>.
            </div>
          </form>
        </div>
        <div className="postLogin">
          {afterContent}
        </div>
    </div>
    </div>

    {showCookieAccept ? (
        <>
          <div className="blackout_back"></div>
          <div className="modal_wrap">
                <div className="modal_box">
                      <h5>We use cookies to improve your experience</h5>
                      <p>This website stores cookies on your computer. These cookies are used to improve your website experience and provide more personalized services to you, 
                        both on this website and through other media. To find out more about the cookies we use, 
                        see our <a href="https://mediavax.ai/privacy-policy" target="_blank" rel="noopener noreferrer">Privacy Policy</a>.</p>
                      <div className="buttons">
                      <Button
                          className="cookie-btn"
                          type="submit"
                          text="Accept and Close"
                          onClick={acceptCookies}
                        />
                      </div>
                </div>
          </div>
        </>
    ) : (<></>)}
    </>
  );
};

export default Login;