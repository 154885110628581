import React, { createContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Cookies from 'universal-cookie';

const AuthContext = createContext();
const { Provider } = AuthContext;

const AuthProvider = ({ children }) => {

  const history = useHistory();
  const cookies = new Cookies();
  const token = cookies.get('spacerace');

  let cookiepolicy  = cookies.get('CookiesAccepted');
  let userInfo = localStorage.getItem('userInfo');
  const expiresAt = localStorage.getItem('expiresAt');


  try {
      userInfo = JSON.parse(userInfo);
      if (userInfo === null) {
        userInfo = {};
      }
  } catch (error) {
      userInfo = {};
  }
  const  [authState,setAuthState] = useState({
      token: token ? token : '',
      expiresAt: expiresAt ? expiresAt : '',
      userInfo: userInfo,
  });
  

  const setAuthInfo = (data) => {
    // cookies.set('spacerace', token, { path: '/',htmlOnly: true, });
    
    const {token,userInfo,expiresAt,rememberMe} = data;
    if (rememberMe) {
      cookies.set('spacerace', token, { path: '/',htmlOnly: true, maxAge: 60*60*24*30});
    } else {
      cookies.set('spacerace', token, { path: '/',htmlOnly: true, });
    }
    localStorage.setItem('userInfo', JSON.stringify(userInfo));
    localStorage.setItem('expiresAt', expiresAt);
    setAuthState({
      token,
      userInfo,
      expiresAt
    });
    
  }

  const logout = () => {
    cookies.remove('spacerace');
    localStorage.removeItem('token');
    localStorage.removeItem('userInfo');
    localStorage.removeItem('expiresAt');
    setAuthState({
      token: null,
      expiresAt: null,
      userInfo: {}
    });
    history.push('/');
  }

  const getAccess = (grant) => {
    if (typeof authState.userInfo.role === 'undefined') {
        return '';
    }
    return grant === authState.userInfo.role
  }

  const checkAccesslist = grant => {
      let found = 0;
      for (let g in grant) {
          let access = grant[g];

          if (access === 'loggedIn') {
              if (isAuthenticated()) {
                  found++;
              }
          } else if (access === 'loggedOut') {
            if (!isAuthenticated()) {
                found++;
            }
          } else {
              if (getAccess('admin')) {
                found++;
              }
              if (getAccess(access)) {
                  found++;
              }
          }
      }
      if (found >  grant.length) {
        return true;
      }
      return found === grant.length;
  }

  const isAuthenticated = () => {
    if (!authState.token || !authState.expiresAt) {
      return false;
    }
    return new Date().getTime() / 1000 < authState.expiresAt;

  }

  const getUserInfo = ( field ) => {
    return authState.userInfo[field]
  }

  const getFullName = () => {
    return getUserInfo('firstName').trim() + ' ' + getUserInfo('lastName').trim();
  }
  
  const getInitails = () => {
    let fullName = getFullName();
    let nameObj = fullName.split(' ');
    let initails = '';
    for (let n in nameObj) {
       initails += nameObj[n][0];
    }
    return initails;

  }

  const isAdmin = () => {
    return authState.userInfo.role === 'admin';
  }

  const CookiesIsAccepted = () => {
      if (typeof cookiepolicy === 'undefined') {
        return 0;
      } else if (cookiepolicy === 'accepted') {
        return 1;
      }
      return 0;
  }

  const SetCookiesAccepted = () => {
      cookies.set('CookiesAccepted', 'accepted', { path: '/',htmlOnly: true, maxAge: 60 * 60 * 24 * 365,});
      cookiepolicy = 'accepted';
  }

  return (
    <Provider
      value={{
        authState, 
        setAuthState : authInfo => setAuthInfo(authInfo),
        isAuthenticated,
        logout,
        isAdmin,
        getAccess,
        checkAccesslist,
        getFullName,
        getInitails,
        getUserInfo,
        CookiesIsAccepted,
        SetCookiesAccepted
      }}
    >
      {children}
    </Provider>
  );
};

export { AuthContext, AuthProvider };