import React,{useState} from 'react';


const RememberMe = ({handleChange }) => {
    const [IsChecked, setIsChecked] = useState(false);

    const updateChange = (e) => {
        let tempCheck = !IsChecked;
        setIsChecked(tempCheck);
        handleChange(tempCheck);
    }
    return (
        <div className="RememberMe black-text">
            <input type="checkbox" checked={IsChecked} onChange={updateChange} />
            <span className="black-text">Remember Me</span>
        </div>
    )
}

export default RememberMe