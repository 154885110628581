import React, { createContext, useContext } from 'react';
import axios from 'axios';
import { AuthContext } from './AuthContext';

const FetchContext = createContext();
const { Provider } = FetchContext;

const FetchProvider = ({ children }) => {
  const authContext = useContext(AuthContext);
  const authAxios = axios.create({
    baseURL: 'https://dmhhh49ag5.execute-api.us-east-1.amazonaws.com/api/' //DEV
    // baseURL: 'https://bjvzypgbog.execute-api.us-east-1.amazonaws.com/api/', //PROD  
    // baseURL: process.env.REACT_APP_API_URL
  });

  
 

  authAxios.interceptors.request.use(
    config => {
      const {baseURL} = config;
      // const allowedOrigins = [process.env.REACT_APP_API_URL];
      const allowedOrigins = ['https://dmhhh49ag5.execute-api.us-east-1.amazonaws.com/api/']; //DEV
      // const allowedOrigins = ['https://bjvzypgbog.execute-api.us-east-1.amazonaws.com/api/']; //PROD
    
      if (allowedOrigins.includes(baseURL)){
        config.headers.Authorization = `Bearer ${authContext.authState.token}`;
      }
      return config;
    },
    error => {
      return Promise.reject(error);
    }
    
  )

  return (
    <Provider
      value={{
        authAxios
      }}
    >
      {children}
    </Provider>
  );
};

export { FetchContext, FetchProvider };
