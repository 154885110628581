import React, {  Component } from 'react'
import Header from './components/Header';
import Hyperlink from './components/common/Hyperlink';
import './App.css';
export class AppStart extends Component {
    constructor(props) {
        super(props);
        this.getClass = this.getClass.bind(this);
        this.getYear = this.getYear.bind(this);
    }
    getClass = () => {
        const { pageSlug} = this.props;
        return 'pageStartWrapper ' + pageSlug;
    }

    getYear = () => {
        let now  = new Date(); 
        return new Intl.DateTimeFormat('en', { year: 'numeric' }).format(now);
    }
    render() {
        const { children,pageSlug} = this.props;
        return (
            <div className={this.getClass()}>
                <div className="pageLeft">
                    <Header pageSlug={pageSlug}/>
                    <div className="pageStartcontent">
                        {children}
                    </div>
                </div>
                <div className="pageRight">
                    <div className="info_block">
                        <div className="backimage"></div>
                        <Hyperlink
                            className="signup"
                            to="sign-up"
                            >Sign up for Mediavax</Hyperlink>
                        <h2>Explore disinformation narratives on social media with a MediaVax account</h2>
                        <div className="content"><p>This is an initial <strong>alpha release</strong> product, developed to allow you to dive into disinformation 
                            narratives and provide feedback. As such, some features are not yet fully functional.</p>
                            <p>To learn more, visit <a href="https://mediavax.ai/" target="_blank" rel="noopener noreferrer" >mediavax.ai</a>. 
                            If you have questions, please email support at <a href="mailto:Diane@mediavax.ai" target="_blank" rel="noopener noreferrer" >Diane -MediaVax User Support</a></p>
                       </div>
                    </div>
                    <div className="copyright">© {this.getYear()} MediaVax, ALL RIGHTS RESERVED</div>
                </div>
                
            </div>
        )
    }
}

export default AppStart
