import React, {lazy,Suspense,useEffect,useState }  from 'react';
import {
  BrowserRouter as Router,
  Route,
  Switch
} from 'react-router-dom';
import { AuthProvider} from './context/AuthContext';
import { FetchProvider } from './context/FetchContext';
import AuthenticatedRoute from './components/AuthenticatedRoute';
import AppStart from './AppStart';
import AppShell from './AppShell';
import Loading from './components/Loading';

import FourOFour from './pages/FourOFour';
import Home from './pages/Home';
import ErrorBoundary from './components/ErrorBoundary';


/**************************User routes**************************/
const AdminUsers = lazy(() => import('./admin/pages/AdminUsers'));
const AdminUserNew = lazy(() => import('./admin/pages/AdminUserNew'));
const AdminFAQs = lazy(() => import('./admin/pages/AdminFAQs'));
const AdminFAQNew = lazy(() => import('./admin/pages/AdminFAQNew'));
const AdminShell = lazy(() => import('./admin/AdminShell'));

/**************************User routes**************************/
const SignUpPage = lazy(() => import('./pages/SignupPage'));
const ForgotPasswordPage = lazy(() => import('./pages/ForgotPasswordPage'));
const Dashboard = lazy(() => import('./pages/Dashboard'));
const Profile = lazy(() => import('./pages/Profile'));
const Faq = lazy(() => import('./pages/Faq'));
const FaqAdmin = lazy(() => import('./pages/FaqAdmin'));

const AppRoutes = () => {

  const [showPopUps,SetShowPopups] = useState(false);



  useEffect(() => {
      window.addEventListener('scroll', isSticky);
      return () => {
          window.removeEventListener('scroll', isSticky);
      };
  });

  const isSticky = (e) => {
      const header = document.querySelector('.page-wrapper');
      if (typeof header !== 'undefined' && header !== null) {
          const scrollTop = window.scrollY;
          if (typeof header.classList !== 'undefined' && header.classList !== null) {
          scrollTop >= 83 ? header.classList.add('is-sticky') : header.classList.remove('is-sticky');
          }
      }
  };
    return (
      <Suspense fallback={<Loading />} >
        <Switch>
          {/**************************admin routes**************************/}
              <AuthenticatedRoute exact path="/admin/users" grant={['loggedIn','user']} send_to="/">
                <AdminShell  pageSlug="admin-users">
                  <AdminUsers />
                </AdminShell>
              </AuthenticatedRoute>
              <AuthenticatedRoute exact path="/admin/user-new" grant={['loggedIn','user']} send_to="/">
                <AdminShell  pageSlug="admin-user-new">
                  <AdminUserNew />
                </AdminShell>
              </AuthenticatedRoute>
              <AuthenticatedRoute exact path="/admin/faqs" grant={['loggedIn','user']} send_to="/">
                <AdminShell  pageSlug="admin-faqs">
                  <AdminFAQs />
                </AdminShell>
              </AuthenticatedRoute>
              <AuthenticatedRoute exact path="/admin/faq-new" grant={['loggedIn','user']} send_to="/">
                <AdminShell  pageSlug="admin-faq-new">
                  <AdminFAQNew />
                </AdminShell>
              </AuthenticatedRoute>
          {/**************************user routes**************************/}
              <AuthenticatedRoute exact path="/dashboard" grant={['loggedIn','user']} send_to="/">
                <AppShell   pageSlug="dashboard" 
                            showPopUps={showPopUps} 
                            changePopups={SetShowPopups}  

                            >
                  <Dashboard showPopUps={showPopUps} 
                            />
                </AppShell>
              </AuthenticatedRoute>
              <AuthenticatedRoute exact path="/loading" grant={['loggedIn','user']} send_to="/">
                <Loading />
              </AuthenticatedRoute>
              <AuthenticatedRoute exact path="/profile" grant={['loggedIn','user']} send_to="/">
                  <Profile />
              </AuthenticatedRoute>
              <AuthenticatedRoute exact path="/faq" grant={['loggedIn','user']} send_to="/">
                <AppShell  pageSlug="faq">
                  <Faq />
                </AppShell>
              </AuthenticatedRoute>
              <AuthenticatedRoute exact path="/faq-admin" grant={['loggedIn','user']} send_to="/">
                <AppShell  pageSlug="faq-admin">
                  <FaqAdmin />
                </AppShell>
              </AuthenticatedRoute>

          {/**************************public routes**************************/}
              <AuthenticatedRoute exact path="/sign-up" grant={['loggedOut']} send_to="/dashboard">
                <AppStart  pageSlug="sign-up">
                  <SignUpPage />
                </AppStart>
              </AuthenticatedRoute>
              <AuthenticatedRoute exact path="/forgot-password" grant={['loggedOut']} send_to="/dashboard">
                <AppStart  pageSlug="forgot-password">
                  <ForgotPasswordPage />
                </AppStart>
              </AuthenticatedRoute>
              <AuthenticatedRoute exact path="/" grant={['loggedOut']} send_to="/dashboard">
                <AppStart   pageSlug="home">
                  <Home />
                </AppStart>
              </AuthenticatedRoute>
              <Route path="*">
                <AppStart   pageSlug="404">
                  <FourOFour />
                </AppStart>
              </Route>
        </Switch>
      </Suspense>
    );
};


function App() {
  return (
    <Router>
        <ErrorBoundary>
          <AuthProvider>
            <FetchProvider>
              <AppRoutes />
            </FetchProvider>
          </AuthProvider>
        </ErrorBoundary>
    </Router>
  );
}

export default App;
