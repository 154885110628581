import React from 'react';
// import React, { useContext } from 'react';
// import { AuthContext } from './../context/AuthContext';
import Login from '../components/Login';

const Home = () => {
  // const authContext = useContext(AuthContext);
  // const {userInfo} = authContext;
  return (
    <>
      <section className="login">
        <Login ><h1>Login</h1></Login>
      </section>
    </>
  );
};

export default Home;
